import React from 'react';
import { Box, Typography, Divider, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useYupObject, useQuery } from 'hooks';
import { Formik } from 'formik';
import { Form, isSaveButtonDisabled } from 'components/core';
import { useHistory } from 'react-router-dom';
import routes from 'helpers/routes';
import { useHavePermission } from 'hooks/useHavePermission';
import { PermissionRole } from 'types/user.types';
import { PartnerType, RecordingType } from 'types/channels.types';
import SelectManuFacturer from 'components/SelectManufacturer';
import { ChannelForm } from 'components/Channel';
import { IntelbrasChannel } from 'types/intelbras.types';
import ChannelTestButton from '../../ChannelTestButton/ChannelTestButton';

type IntelbrasPROPS = {
  customerId: string;
  initialValues: IntelbrasChannel;
  doSubmit: (camera: IntelbrasChannel) => Promise<void>;
  showDeleteButton?: boolean;
  onClose: () => void;
  doDelete?: (id: number) => Promise<void>;
};

export function IntelbrasForm({
  customerId,
  initialValues,
  doSubmit,
  doDelete,
  onClose,
  showDeleteButton,
}: IntelbrasPROPS) {
  const excludePermission = useHavePermission(PermissionRole.REMOVE_CHANNEL);
  const { t } = useTranslation(['channels', '_common', 'forms']);
  const yup = useYupObject();
  const history = useHistory();
  const query = useQuery();

  const validationSchema = yup.object({
    name: yup.string().trim().max(100).min(3).required().noSpecialCharacters(),
    serialNumber: yup
      .string()
      .required()
      .max(255)
      .min(9, t('forms:number_min', { min: 9 }))
      .trim()
      .test('white-space', t('_common:input_white_spaces_validation'), (serial) => {
        if (!serial) return true;

        return !(serial.split(' ').length > 1);
      }),
    p2pChannel: yup.number().required().min(1).max(99999),
    user: yup
      .string()
      .trim()
      .max(100)
      .required()
      .test('white-space', t('_common:input_white_spaces_validation'), (username) => {
        if (!username) return true;

        return !(username.split(' ').length > 1);
      }),
    password: yup
      .string()
      .required()
      .max(100)
      .test('white-space', t('_common:input_white_spaces_validation'), (username) => {
        if (!username) return true;

        return !(username.split(' ').length > 1);
      }),
    p2pPartner: yup.string().trim().required(),
    channelGroup: yup
      .object({
        id: yup.number().required(),
        label: yup.string().required(),
      })
      .required(),
    recordingType: yup.string().oneOf(Object.values(RecordingType)).required(),
    retentionTime: yup.number().when('recordingType', {
      is: (recordingType) => recordingType !== RecordingType.LIVE,
      then: yup.number().max(9999).min(1).integer().required(),
      otherwise: yup.number(),
    }),
    subtypeStream: yup.boolean(),
    channelPrivate: yup.bool(),
    activeTransmissionCreditLimit: yup.boolean(),
    transmissionCreditLimit: yup
      .number()
      .max(100000000)
      .when('activeTransmissionCreditLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      }),
    activeStorageLimit: yup.bool(),
    storageLimit: yup
      .number()
      .max(1000000)
      .when('activeStorageLimit', {
        is: true,
        then: yup.number().min(1).required(),
        otherwise: yup.number().min(0).required(),
      }),
    simpleScheduledRecording: yup
      .object({
        id: yup.number(),
        name: yup.string(),
      })
      .nullable(),
    port: yup
      .number()
      .integer()
      .required()
      .min(0, t('form:invalid_port_number'))
      .max(65535, t('form:invalid_port_number')),
  });

  const handleManuFacturerChange = (event: SelectChangeEvent<PartnerType>) => {
    event.target.value === PartnerType.HIKVISION &&
      history.push(routes.customer.channel.create.registerHikvision(customerId));
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        ...initialValues,
        channelGroup: initialValues.channelGroup && {
          id: initialValues.channelGroup.id,
          label: initialValues.channelGroup.name,
        },
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const isScheduleLinked = Boolean(
          initialValues.recordingType === RecordingType.SCHEDULE &&
            initialValues.simpleScheduledRecording?.id
        );

        const hasUnlinkedSchedule =
          isScheduleLinked && values.recordingType !== RecordingType.SCHEDULE;

        try {
          await doSubmit({
            ...values,
            channelGroup: values.channelGroup
              ? {
                  id: values.channelGroup.id,
                  name: values.channelGroup.label,
                }
              : undefined,
            simpleScheduledRecording: hasUnlinkedSchedule ? null : values.simpleScheduledRecording,
          });
          setSubmitting(false);
          onClose();
        } catch (error) {
          console.error(error);
          setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <form
          aria-label="intelbrasForm"
          style={{
            overflow: 'hidden',
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '100%',
            height: '100%',
          }}
          onSubmit={formik.handleSubmit}
        >
          {!initialValues.id && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '1.8rem',
                marginLeft: '2rem',
                marginTop: '1rem',
              }}
            >
              <SelectManuFacturer
                value={formik.values.p2pPartner}
                onChange={handleManuFacturerChange}
              />
            </Box>
          )}
          <Box
            sx={{
              flex: '1 1 auto',
              overflow: 'auto',
              px: 4,
              py: 1.5,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                marginBottom: '0.6rem',
              }}
            >
              {t('channels:warning_in_intelbras_page')}
            </Typography>

            <Typography
              variant="h6"
              sx={{
                marginBottom: '0.6rem',
                fontSize: '16px',
              }}
            >
              {t('channels:information')}
            </Typography>

            <ChannelForm.StatusInput />

            <Box
              sx={{
                marginBottom: '1.2rem',
                display: 'flex',
                gap: 2,
              }}
            >
              <ChannelForm.NameInput />
              <ChannelForm.PortInput />
            </Box>
            <Box
              sx={{
                marginBottom: '1.2rem',
                display: 'flex',
                gap: 2,
              }}
              display="flex"
            >
              <ChannelForm.SerialNumberInput />
              <ChannelForm.P2PChannelInput />
            </Box>
            <Box
              sx={{
                marginBottom: '1.2rem',
                display: 'flex',
              }}
            >
              <ChannelForm.UsernameInput />
              <ChannelForm.PasswordInput />
            </Box>
            <ChannelForm.ExtraStreamCheckBox />

            <Box display="inline-flex" width="100%" mb={4}>
              <ChannelTestButton.Intelbras
                isDisabled={
                  (!!formik.touched.serialNumber && Boolean(formik.errors.serialNumber)) ||
                  (!!formik.touched.user && Boolean(formik.errors.user)) ||
                  (!!formik.touched.password && Boolean(formik.errors.password)) ||
                  (!!formik.touched.p2pChannel && Boolean(formik.errors.p2pChannel)) ||
                  (!!formik.touched.port && Boolean(formik.errors.port)) ||
                  Boolean(
                    !formik.values.serialNumber ||
                      !formik.values.user ||
                      !formik.values.password ||
                      formik.values.p2pChannel <= -1
                  )
                }
                data={{
                  name: formik.values.name,
                  serial: formik.values.serialNumber,
                  channel: formik.values.p2pChannel,
                  user: formik.values.user,
                  password: formik.values.password,
                  port: formik.values.port || 554, // default RTSP port
                }}
                batchKey={formik.values.uuid}
              />
            </Box>
            <Box
              sx={{
                marginBottom: '1.2rem',
              }}
              display="flex"
            >
              <ChannelForm.ChannelGroupSelect />
              <ChannelForm.RecordingTypeSelect />
              <ChannelForm.RetentionTimeInput />
            </Box>
            <Box>
              <ChannelForm.EnablePreAlarmInput />
            </Box>
            <Box display="flex">
              <ChannelForm.CreditLimitCheckBox />
              <ChannelForm.StorageLimitCheckBox />
            </Box>
            <Box display="flex" width="100%" marginBottom={4}>
              <ChannelForm.CreditLimitInput />
              <ChannelForm.StorageLimitInput />
            </Box>
            <ChannelForm.PrivateChannel />
            <ChannelForm.RecordingScheduleSelect />
          </Box>
          <Divider />
          <Box
            sx={{
              flex: '0 0 auto',
              padding: '20px',
              textAlign: 'end',
            }}
          >
            {showDeleteButton && excludePermission && (
              <Form.DeleteButton
                isSubmitting={formik.isSubmitting}
                confirmModalDescription={t(
                  'not_possible_retrieve_information_and_view_image_camera'
                )}
                confirmModalTitle={t('want_delete')}
                onConfirmDelete={async (setIsDeleting) => {
                  try {
                    if (!doDelete) return;
                    if (!initialValues.id) return;
                    await doDelete(initialValues.id);
                    setIsDeleting(false);
                    history.push(query.get('returnRoute') || routes.customer.root());
                  } catch (error) {
                    console.error(error);
                    setIsDeleting(false);
                  }
                }}
              />
            )}
            <Form.CancelButton isSubmitting={formik.isSubmitting} onClick={onClose} />
            <ChannelForm.SaveButton
              disabled={
                !formik.isValid ||
                !formik.dirty ||
                isSaveButtonDisabled({
                  initialValues: formik.initialValues,
                  isSubmitting: formik.isSubmitting,
                  values: formik.values,
                })
              }
              isSubmitting={formik.isSubmitting}
              onClick={formik.handleSubmit}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
}
