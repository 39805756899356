import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  Skeleton,
  Box,
} from '@mui/material';
import { normalizeEvent } from 'atoms/playback';
import { timeFormat } from 'd3';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelEvent } from 'types/playback.types';
import iconAtencaoJson from 'assets/icons8-atencao.json';
import iconSelecionadoJson from 'assets/icons8-selecionado.json';
import iconCancelarJson from 'assets/icons8-cancelar.json';
import Lottie from 'react-lottie';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useFetch } from 'hooks';
import { ExpandMore } from '@mui/icons-material';
import { Link as RouteLink } from 'react-router-dom';
import routes from 'helpers/routes';
import Permissioned from 'components/Permissioned/Permissioned';
import { PermissionRole } from 'types/user.types';

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      maxWidth: '820px',
      maxHeight: '232px',
      overflow: 'auto',
    },
    listItem: {
      padding: '12px 16px',
    },
    paperItem: {
      marginBottom: '4px',
    },
    textDate: {
      marginRight: '24px',
      flex: '0 0 auto',
    },
  })
);

function ChannelLatestEvents({ channelId }: { channelId: number }) {
  const { t } = useTranslation('channels');
  const classes = useStyles();

  const { data: events } = useFetch<ChannelEvent[]>(`/v1/channels/${channelId}/events`, {
    normalizeData: (data) => data.map(normalizeEvent),
    refreshInterval: 15000,
  });

  if (!events || events.length === 0)
    return <Typography>{t('no_events_occurred_on_this_camera')}</Typography>;

  return (
    <>
      <List component="nav" className={classes.list} aria-label={t('latest_events')}>
        {events.map((item) =>
          item.internalDesc ? (
            <ItemAccordion key={item.id} {...{ item }} />
          ) : (
            <Item key={item.id} {...{ item }} />
          )
        )}
      </List>
      <Permissioned role={PermissionRole.VIEW_CHANNEL}>
        <Link component={RouteLink} to={routes.events.root({ selectedChannel: [channelId] })}>
          <Button
            color="primary"
            variant="contained"
            sx={{
              width: '100%',
              maxWidth: '820px',
            }}
          >
            {t('view_all_events')}
          </Button>
        </Link>
      </Permissioned>
    </>
  );
}

function Item(props: React.PropsWithChildren<{ id: number }>): React.ReactElement | null;
function Item(props: React.PropsWithChildren<{ item: ChannelEvent }>): React.ReactElement | null;
function Item({ item, id }: { item?: ChannelEvent; id?: number }) {
  const { t } = useTranslation('channel_events');
  const classes = useStyles();

  const iconData = useMemo(() => {
    switch (item?.code || 200) {
      case 200:
        return iconSelecionadoJson;
      case 400:
      case 424:
        return iconCancelarJson;

      default:
        return iconAtencaoJson;
    }
  }, [item]);

  return (
    <Paper
      className={classes.paperItem}
      sx={{
        width: '100%',
      }}
    >
      <ListItem button className={classes.listItem}>
        <Typography className={classes.textDate}>
          {item ? timeFormat('%d %B %Y %H:%M')(item.date) : <Skeleton width="138px" />}
        </Typography>
        <ListItemIcon>
          <Lottie
            width="1.5rem"
            height="1.5rem"
            options={{
              animationData: iconData,
              rendererSettings: {},
              loop: false,
            }}
          />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ noWrap: true }}>
          {item ? t(String(item.code)) : <Skeleton />}
        </ListItemText>
      </ListItem>
    </Paper>
  );
}

export function Loading() {
  const classes = useStyles();

  return (
    <List component="nav" className={classes.list}>
      {Array.from({ length: 3 }).map((item, id) => (
        // eslint-disable-next-line react/no-array-index-key
        <Item key={id} {...{ id }} />
      ))}
    </List>
  );
}

export function ItemAccordion({ item }: { item: ChannelEvent }) {
  const { t } = useTranslation('channel_events');
  const classes = useStyles();

  const iconData = useMemo(() => {
    switch (item?.code || 200) {
      case 200:
        return iconSelecionadoJson;
      case 400:
      case 424:
        return iconCancelarJson;

      default:
        return iconAtencaoJson;
    }
  }, [item]);

  const getValueFromMiddleware = (value: string) => {
    let result = value;

    if (value === 'Start Live Stream') {
      result = t('events:start_live_stream');
    }

    if (value === 'START' || value === 'STOP') {
      result = t(
        'channel_recording_requests:the_requester_system_made_a_request_for_requestType_the_recording',
        {
          requester: t('_common:recording_schedule'),
          requestType:
            value === 'START'
              ? t('channel_recording_requests:start')
              : t('channel_recording_requests:stop'),
        }
      );
    }

    return result;
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography className={classes.textDate}>
            {item ? timeFormat('%d %B %Y %H:%M')(item.date) : <Skeleton width="138px" />}
          </Typography>
          <ListItemIcon>
            <Lottie
              width="1.5rem"
              height="1.5rem"
              style={{
                color: 'black',
              }}
              options={{
                animationData: iconData,
                rendererSettings: {},
                loop: false,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              noWrap: true,
            }}
          >
            {t(String(item.code))}
          </ListItemText>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{getValueFromMiddleware(item.internalDesc)}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default ChannelLatestEvents;
