import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  styled,
  Typography,
  useTheme,
  Link,
  Divider,
} from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { ChannelSimple } from 'types/mosaics.types';
import { useTranslation } from 'react-i18next';
import MosaicPagination from 'components/MosaicPagination';
import { MosaicPaginationProps } from 'components/MosaicPagination/MosaicPagination';
import { ZoomOut } from 'components/core/Icons';
import { Link as RouteLink } from 'react-router-dom';
import routes from 'helpers/routes';
import ChannelCronologicBar from './V2ChannelCronologicBar';
import MergedCronologicBar from './V2MergedCronologicBar';
import { useChannelControlsBar } from './V2ChannelControlsBar';
import useMergedControlsBar from './V2useMergedControlsBar';
import VideoControlsBar from './V2VideoControlsBar';
import MosaicSettings from './MosaicSettings';

const ChannelsBarsContainer = styled(Box)(({ theme }) => ({
  background: `linear-gradient(${theme.palette.background.paper} 33%, rgba(54, 54, 54, 0)),
    linear-gradient(rgba(54, 54, 54, 0), ${theme.palette.background.paper} 66%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)) 0 100%`,
  backgroundColor: theme.palette.background.paper,
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'local, local, scroll, scroll',
  backgroundSize: '100% 96px, 100% 96px, 100% 32px, 100% 32px',
  '&::-webkit-scrollbar': {
    width: '0.6125rem',
    backgroundColor: theme.palette.background.paper,
  },
}));

const CRONOLOGICBAR_HEIGHT = 62;

type Channel = ChannelSimple & {
  order: number;
};

interface BottomPanelProps {
  mosaicId: number;
  channels: Channel[];
  orderSelected: number | null;
  propsPagination: MosaicPaginationProps;
}

function BottomPanel({ mosaicId, channels, orderSelected, propsPagination }: BottomPanelProps) {
  const theme = useTheme();
  const [opened, setOpened] = useState(false);
  const [nowLiveDate] = useState(new Date());
  const [timeToPass, setTimeToPass] = React.useState(3600);
  const { t } = useTranslation('playback');

  const channelSelected = useMemo(() => {
    if (orderSelected) {
      return channels.find((c) => c.order === orderSelected);
    }
    return undefined;
  }, [channels, orderSelected]);

  const channelsId = useMemo(() => channels.map((c) => c.id), [channels]);
  const noneChannel = channels.length === 0;

  const channelId = channels.length === 1 ? channels[0].id : channelSelected?.id;
  const id = channelId
    ? `cronologic-bar-channel-selected-${channelId}`
    : 'cronologic-bar-all-channels';

  const name = channels.find((c) => c.id === channelId)?.name ?? t('playback:all_channels') ?? '';
  const controlBar = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {channelId ? (
        <ChannelControlBar
          key={String(opened)}
          {...{
            mosaicId,
            nowLiveDate,
            channelId,
            propsPagination,
            opened,
            timeToPass,
            setTimeToPass,
          }}
        />
      ) : (
        <MergedControlBar
          key={String(opened)}
          {...{
            channelsId,
            mosaicId,
            nowLiveDate,
            propsPagination,
            opened,
            timeToPass,
            setTimeToPass,
          }}
        />
      )}
    </Box>
  );
  const cronologicBar = channelId ? (
    <ChannelCronologicBar
      key={channelId}
      height={CRONOLOGICBAR_HEIGHT}
      channelId={channelId}
      order={channels.find((c) => c.id === channelId)?.order}
      {...{ nowLiveDate, mosaicId }}
    />
  ) : (
    <MergedCronologicBar height={CRONOLOGICBAR_HEIGHT} {...{ nowLiveDate, channelsId, mosaicId }} />
  );
  const openAllTimelinePanelComponent =
    channels.length > 1 ? (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button
          size="small"
          aria-label="expand playback panel"
          onClick={() => setOpened(true)}
          sx={{ py: 0 }}
        >
          <ArrowDropUp />
          <Typography variant="body2" sx={{ textTransform: 'none' }}>
            {t('playback:view_all_timelines')}
          </Typography>
        </Button>
      </Box>
    ) : null;

  if (noneChannel) {
    return <NoneChannelControl {...{ propsPagination, timeToPass, setTimeToPass, mosaicId }} />;
  }
  return (
    <>
      <ShrinkedPanel
        {...{ id, name, controlBar, cronologicBar, openAllTimelinePanelComponent, opened }}
      />
      <Box
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        zIndex={opened ? 0 : theme.zIndex.drawer}
      >
        <Collapse in={opened}>
          <Paper elevation={4}>
            <Box role="region" title={opened ? 'expanded playback panel' : undefined}>
              <Paper>
                <Box display="flex" justifyContent="center" padding="4px">
                  <Button
                    aria-label="shrink playback panel"
                    size="small"
                    onClick={() => setOpened(false)}
                    sx={{
                      width: '100%',
                    }}
                  >
                    <ArrowDropDown />
                  </Button>
                </Box>
                <ChannelsBarsContainer maxHeight="288px" overflow="auto" marginRight="8px">
                  {channels.map((channel, i) => (
                    <Box key={channel.id}>
                      <Box
                        role="region"
                        aria-labelledby={id}
                        id={`cronologic-bar-channel-name-${channel.id}`}
                        key={channel.id}
                        sx={{
                          display: 'flex',
                          margin: '0px 4px 8px 4px',
                          alignItems: 'center',
                          p: channel.order === orderSelected ? 0.5 : undefined,
                          border:
                            channel.order === orderSelected
                              ? `1px solid ${theme.palette.primary.main}`
                              : undefined,
                        }}
                      >
                        <Typography
                          id={`cronologic-bar-channel-name-${channel.id}`}
                          sx={{
                            width: '170px',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            '-webkit-line-clamp': '2',
                            'line-clamp': '2',
                            '-webkit-box-orient': 'vertical',
                          }}
                          variant="body2"
                        >
                          {channel.name}
                        </Typography>
                        {(channel.order !== orderSelected || opened) && (
                          <Box sx={{ flex: '1 1 auto' }}>
                            <ChannelCronologicBar
                              key={channel.id}
                              height={52}
                              channelId={channel.id}
                              order={channel.order}
                              {...{ nowLiveDate, mosaicId }}
                            />
                          </Box>
                        )}
                      </Box>
                      <Divider hidden={i === channels.length - 1} sx={{ mb: 1 }} />
                    </Box>
                  ))}
                </ChannelsBarsContainer>
                {controlBar}
              </Paper>
            </Box>
          </Paper>
        </Collapse>
      </Box>
    </>
  );
}

interface ShrinkedPanelProps {
  id: string;
  controlBar?: React.ReactNode;
  name: string;
  cronologicBar?: React.ReactNode;
  openAllTimelinePanelComponent?: React.ReactNode;
  opened: boolean;
}

function ShrinkedPanel({
  id,
  name,
  controlBar,
  cronologicBar,
  openAllTimelinePanelComponent,
  opened,
}: ShrinkedPanelProps) {
  return (
    <Box role="region" aria-label="shrinked playback panel" flex="0 0 auto" padding="4px">
      {!opened && (
        <Box
          role="region"
          aria-labelledby={id}
          sx={{ display: { sm: 'block', lg: 'flex' }, maxWidth: '100%' }}
        >
          <Box
            sx={{
              flex: '1 1 auto',
              minWidth: { sm: '100%', lg: '520px' },
              maxWidth: '700px',
              overflow: 'hidden',
            }}
          >
            {controlBar}
          </Box>
          <Box sx={{ flex: '1 1 auto' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                ml: 4.3,
                mr: 13,
              }}
            >
              <Typography id={id} variant="body2">
                {name}
              </Typography>
              {openAllTimelinePanelComponent}
            </Box>
            {cronologicBar}
          </Box>
        </Box>
      )}
    </Box>
  );
}

function NoneChannelControl({
  propsPagination,
  mosaicId,
}: {
  propsPagination: MosaicPaginationProps;
  mosaicId: number;
}) {
  return (
    <Box
      role="region"
      aria-label="shrinked playback panel"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: '0 0 auto',
        p: 0.5,
        mb: 0.5,
      }}
    >
      <Box sx={{ mr: 1.5 }}>
        <MosaicSettings.Container>
          <MosaicSettings.ViewVideoDetails />
          <MosaicSettings.SigmaIntegrationSwitch />
          <MosaicSettings.DeleteMosaic />
        </MosaicSettings.Container>
      </Box>
      <Link component={RouteLink} aria-label="FullScreen" to={routes.mosaic.fullscreen(mosaicId)}>
        <IconButton size="small" sx={{ p: 0, mr: 1.5 }}>
          <ZoomOut />
        </IconButton>
      </Link>
      <MosaicPagination {...propsPagination} size="small" />
    </Box>
  );
}

function ChannelControlBar({
  channelId,
  mosaicId,
  nowLiveDate,
  propsPagination,
  opened,
  timeToPass,
  setTimeToPass,
}: {
  channelId: number;
  mosaicId: number;
  nowLiveDate: Date;
  propsPagination: MosaicPaginationProps;
  opened: boolean;
  timeToPass: number;
  setTimeToPass: (newTime: number) => void;
}) {
  const {
    paused,
    setPaused,
    muted,
    setMuted,
    widescreen,
    setWidescreen,
    handleChangeDateTimePicker,
    playbackStartDateDataBar,
    selectedDate,
    currentTime,
    live,
    handleWatchLive,
    playbackRate,
    setPlaybackRate,
    handleFowardRewind,
  } = useChannelControlsBar({
    channelId,
    mosaicId,
  });

  return (
    <ControlBar
      {...{
        paused,
        setPaused,
        muted,
        setMuted,
        mosaicId,
        currentTime,
        widescreen,
        setWidescreen,
        nowLiveDate,
        live,
        handleWatchLive,
        selectedDate,
        playbackStartDateDataBar,
        handleChangeDateTimePicker,
        playbackRate,
        setPlaybackRate,
        handleFowardRewind,
        propsPagination,
        opened,
        timeToPass,
        setTimeToPass,
      }}
    />
  );
}
function MergedControlBar({
  channelsId,
  mosaicId,
  nowLiveDate,
  propsPagination,
  opened,
  timeToPass,
  setTimeToPass,
}: {
  channelsId: number[];
  mosaicId: number;
  nowLiveDate: Date;
  propsPagination: MosaicPaginationProps;
  opened: boolean;
  timeToPass: number;
  setTimeToPass: (newTime: number) => void;
}) {
  const {
    paused,
    setPaused,
    muted,
    setMuted,
    widescreen,
    setWidescreen,
    handleChangeDateTimePicker,
    playbackStartDateDataBar,
    selectedDate,
    currentTime,
    live,
    handleWatchLive,
    playbackRate,
    setPlaybackRate,
    handleFowardRewind,
  } = useMergedControlsBar({
    channelsId,
    mosaicId,
  });

  return (
    <ControlBar
      {...{
        paused,
        setPaused,
        muted,
        setMuted,
        mosaicId,
        currentTime,
        widescreen,
        setWidescreen,
        nowLiveDate,
        live,
        handleWatchLive,
        selectedDate,
        playbackStartDateDataBar,
        handleChangeDateTimePicker,
        playbackRate,
        setPlaybackRate,
        handleFowardRewind,
        propsPagination,
        opened,
        timeToPass,
        setTimeToPass,
      }}
    />
  );
}

function ControlBar({
  paused,
  setPaused,
  muted,
  setMuted,
  mosaicId = null,
  currentTime,
  widescreen,
  setWidescreen,
  nowLiveDate,
  live,
  handleWatchLive,
  selectedDate,
  playbackStartDateDataBar,
  handleChangeDateTimePicker,
  setPlaybackRate,
  playbackRate,
  handleFowardRewind,
  propsPagination,
  opened,
  timeToPass,
  setTimeToPass,
}: {
  paused: boolean;
  setPaused: (paused: boolean) => void;
  muted: boolean;
  setMuted: (paused: boolean) => void;
  mosaicId?: number | null;
  currentTime: number;
  widescreen?: boolean;
  setWidescreen: (widescreen: boolean) => void;
  nowLiveDate: Date;
  live: boolean;
  handleWatchLive: () => void;
  selectedDate: Date | null;
  playbackStartDateDataBar: string | null;
  handleChangeDateTimePicker: (v: Date) => void;
  setPlaybackRate: (newValue: number) => void;
  playbackRate: number;
  handleFowardRewind: (time: number) => void;

  propsPagination: MosaicPaginationProps;
  opened: boolean;
  timeToPass: number;
  setTimeToPass: (newTime: number) => void;
}) {
  useEffect(
    function updatePlaybackRate() {
      // Update the playback rate whenever the selectedDate changes or a click occurs on the timeline
      setPlaybackRate(1); // Default
    },

    [selectedDate, setPlaybackRate]
  );
  return (
    <Box
      display="flex"
      aria-label="control bar"
      role="region"
      flex="1 1 auto"
      sx={{
        mr: opened ? undefined : 2,
        flexDirection: opened ? 'row' : 'column',
        p: opened ? 1 : undefined,
      }}
    >
      <Box sx={{ flex: '1 1 auto', display: 'flex', py: 0.5, alignItems: 'center' }}>
        <VideoControlsBar.PlayPauseAction {...{ paused, setPaused }} />
        <VideoControlsBar.MuteUnmuteAction {...{ muted, setMuted }} />
        <VideoControlsBar.WidescreenAction {...{ widescreen, setWidescreen }} />
        <VideoControlsBar.FowardRewindAction.BackButton
          {...{
            handleFowardRewind,
            timeToPass,
          }}
        />
        <VideoControlsBar.FowardRewindAction.FowardButton
          {...{
            handleFowardRewind,
            timeToPass,
            live,
          }}
        />
        <Box sx={{ mr: 1.5, ml: opened ? undefined : 'auto' }}>
          <MosaicSettings {...{ setPlaybackRate, playbackRate, timeToPass, setTimeToPass }} />
        </Box>
        {mosaicId ? (
          <Link
            component={RouteLink}
            aria-label="FullScreen"
            to={routes.mosaic.fullscreen(mosaicId)}
          >
            <IconButton size="small" sx={{ p: 0, mr: 1.5 }}>
              <ZoomOut />
            </IconButton>
          </Link>
        ) : null}
        <Box sx={{ ml: opened ? undefined : 'auto', display: live ? 'none' : undefined }}>
          <VideoControlsBar.WatchLiveButton {...{ live, handleWatchLive }} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', mt: opened ? undefined : 1 }}>
        <MosaicPagination.SelectPage {...propsPagination} size="small" />
        <VideoControlsBar.DateTimePlayback
          {...{
            handleChangeDateTimePicker,
            playbackStartDateDataBar,
            nowLiveDate,
            selectedDate,
            currentTime,
            live,
            handleWatchLive,
          }}
        />
        <Box sx={{ ml: 'auto' }}>
          <MosaicPagination.SelectTemplate {...propsPagination} size="small" />
        </Box>
      </Box>
    </Box>
  );
}

export default BottomPanel;
