import {
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  FormHelperText,
  SelectChangeEvent,
} from '@mui/material';
import { Channel } from 'types/channels.types';
import SelectFetchLoading from 'components/SelectFetchLoading';
import { useFetch } from 'hooks';
import React, { FocusEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

function SelectCustomerChannels({
  values,
  onChange,
  onBlur,
  error,
  helperText,
  placeholder,
  name,
  className,
  customerId,
  label,
  disabled,
  scheduleId,
}: {
  values: number[];
  onChange: (e: SelectChangeEvent<number[]>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  name: string;
  error?: boolean;
  helperText?: React.ReactNode;
  placeholder?: string;
  className?: string;
  customerId: number;
  label?: React.ReactNode;
  disabled?: boolean;
  scheduleId?: string;
}) {
  const { data: channels } = useFetch<Channel[]>(
    `/v1/scheduledRecording/customers/${customerId}/channels`,
    {
      conditionFn: () => !!customerId,
      params: {
        scheduleId,
      },
    }
  );
  const { t } = useTranslation('_common');

  return (
    <FormControl variant="outlined" fullWidth {...{ className, error }}>
      <InputLabel id="event-filter-channel-label">{label || t('channels')}</InputLabel>
      <Select
        {...{ name, placeholder }}
        labelId="event-filter-channel-label"
        id="event-filter-channel"
        label={label || t('channels')}
        onChange={onChange}
        onBlur={onBlur}
        value={values}
        multiple
        disabled={disabled}
        renderValue={(valuesSelected) => {
          const value = valuesSelected.map(
            (id) => channels?.find((channel) => channel.id === id)?.name
          );

          if (value.length > 0) {
            return value.join(', ');
          }

          return '';
        }}
      >
        {channels?.map(({ name: channelName, id }) => (
          <MenuItem key={id} value={id}>
            <Checkbox checked={values.some((selectedId) => selectedId === id)} />
            <ListItemText primary={channelName} />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

// Without

SelectCustomerChannels.Loading = function SelectCustomersLoading() {
  const { t } = useTranslation('_common');
  return <SelectFetchLoading label={t('channels')} />;
};

export default SelectCustomerChannels;
