import React, { useCallback, useEffect } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  actualFowardRewindSecondsAtom,
  currentTimeAtom,
  datesRecordsAtom,
  getRecordedDataInDate,
  lastFowardRewindClickAtom,
  liveSelector,
  mutedAtom,
  pausedAtom,
  playbackRateAtom,
  playbackStartDateDataBarSelector,
  recordsAtom,
  selectedDateAtom,
  selectedDateInPickerAtom,
} from 'atoms/playback';
import { mosaicItemWidescreenSelector } from 'atoms/mosaicItems';
import { exportVideoModalOpenedAtom } from 'atoms/exportVideo';
import { BoxProps } from '@mui/material';
import VideoControlsBar from './V2VideoControlsBar';

export function useChannelControlsBar({
  channelId,
  mosaicId = null,
}: {
  channelId: number;
  mosaicId?: number | null;
}) {
  const [paused, setPaused] = useRecoilState(pausedAtom(channelId));
  const [muted, setMuted] = useRecoilState(mutedAtom(channelId));
  const currentTime = useRecoilValue(currentTimeAtom(channelId));
  const [widescreen, setWidescreen] = useRecoilState(
    mosaicItemWidescreenSelector({ channelId, mosaicId })
  );
  const [live, setLive] = useRecoilState(liveSelector({ mosaicId, channelId }));
  const selectedDate = useRecoilValue(selectedDateAtom({ mosaicId, channelId }));
  const playbackStartDateDataBar = useRecoilValue(
    playbackStartDateDataBarSelector({ mosaicId, channelId })
  );
  const [playbackRate, setPlaybackRate] = useRecoilState(playbackRateAtom(channelId));

  const handleWatchLive = useCallback(() => {
    setLive(true);
  }, [setLive]);

  const handleChangeDateTimePicker = useRecoilCallback(
    ({ set }) => (v: Date | null) => {
      if (v) {
        (async function fetchRecords() {
          const dataRecords = await getRecordedDataInDate(channelId, v);
          set(recordsAtom(channelId), dataRecords.data);
          set(datesRecordsAtom(channelId), dataRecords.startDates);
        })();
      }
      set(selectedDateAtom({ mosaicId, channelId }), v);
      set(selectedDateInPickerAtom, v);
    },
    [channelId]
  );

  const handleFowardRewind = useRecoilCallback(
    ({ set }) => (time: number) => {
      set(actualFowardRewindSecondsAtom, time);
      set(lastFowardRewindClickAtom(channelId), new Date());
    },
    [channelId]
  );

  const doVideoTrimming = useRecoilCallback(({ set }) => () => {
    set(exportVideoModalOpenedAtom, true);
  });

  return {
    paused,
    setPaused,
    muted,
    setMuted,
    currentTime,
    widescreen,
    setWidescreen,
    live,
    handleWatchLive,
    selectedDate,
    playbackStartDateDataBar,
    handleChangeDateTimePicker,
    playbackRate,
    setPlaybackRate,
    handleFowardRewind,
    doVideoTrimming,
  };
}

function ChannelControlsBar({
  channelId,
  mosaicId = null,
  nowLiveDate,
  ...props
}: {
  channelId: number;
  mosaicId?: number | null;
  nowLiveDate: Date;
} & BoxProps) {
  const {
    paused,
    setPaused,
    muted,
    setMuted,
    currentTime,
    widescreen,
    setWidescreen,
    live,
    handleWatchLive,
    selectedDate,
    playbackStartDateDataBar,
    handleChangeDateTimePicker,
    playbackRate,
    setPlaybackRate,
    handleFowardRewind,
    doVideoTrimming,
  } = useChannelControlsBar({
    channelId,
    mosaicId,
  });

  useEffect(
    function updatePlaybackRate() {
      // Update the playback rate whenever the selectedDate changes or a click occurs on the timeline
      setPlaybackRate(1); // Default
    },

    [selectedDate, setPlaybackRate]
  );

  return (
    <VideoControlsBar
      {...{
        paused,
        setPaused,
        muted,
        setMuted,
        mosaicId,
        currentTime,
        widescreen,
        setWidescreen,
        nowLiveDate,
        live,
        handleWatchLive,
        selectedDate,
        playbackStartDateDataBar,
        handleChangeDateTimePicker,
        playbackRate,
        setPlaybackRate,
        handleFowardRewind,
        doVideoTrimming,
      }}
      {...props}
    />
  );
}

export default ChannelControlsBar;
