import React, { Dispatch, SetStateAction, useState } from 'react';
import InfoItem from 'components/InfoItem';
import { useTranslation } from 'react-i18next';
import { Typography, IconButton, Box, useTheme, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useRouteMatch } from 'react-router-dom';
import routes from 'helpers/routes';
import { PartnerType, RecordingType } from 'types/channels.types';
import { PermissionRole } from 'types/user.types';
import Permissioned from 'components/Permissioned';
import { Edit, OpenInNew, Videocam, Delete } from '@mui/icons-material';
import ApiBoundary from 'components/ApiBoundary';
import { ChannelGroup } from 'types/channelGroup.types';
import { SimpleScheduledRecording } from 'types/recordingSchedule.types';
import { ConfirmModal } from 'components/core';
import { useChannelsApi } from 'hooks/channels/useChannelsApi';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';
import { Container, Header } from './ChannelDetails.styles';

import ChannelLatestEvents, { Loading as ChannelLatestEventsLoading } from './ChannelLatestEvents';
import ChannelMosaicChips from './ChannelMosaicChips';

interface Channel {
  id: number;
  name: string;
  url?: string;
  urlOnlyRecord?: string;
  recordingType: RecordingType;
  retentionTime: number;
  channelGroup?: ChannelGroup;
  idMask: string;
  recording: boolean;
  online: boolean;
  channel?: number;
  enablePreAlarm: boolean;
  p2pPartner?: PartnerType;
  serialNumber?: string;
  privateKey?: string;
  status: boolean;
  simpleScheduledRecording?: SimpleScheduledRecording | null;
  connectionTypeEnum?: 'RTMP' | 'RTSP' | 'NO_P2P' | 'P2P';
  port?: boolean;
}

const useStyles = makeStyles((theme) => ({
  iconEdit: {
    marginRight: theme.spacing(5),
  },
}));

interface EditProps {
  partner: PartnerType;
  customerID: string | number;
  id: number;
  path: string;
  protocol?: 'RTMP' | 'RTSP' | 'NO_P2P' | 'P2P';
}

function checkProtocolByEnum(enumType?: 'RTMP' | 'RTSP' | 'NO_P2P' | 'P2P') {
  switch (enumType) {
    case 'RTMP':
      return 'RTMP URL';
    case 'RTSP':
      return 'RTSP URL';
    default:
      return 'URL';
  }
}

function SendToEditForm({ partner, customerID, id, path, protocol }: EditProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  if (partner === PartnerType.HIKVISION) {
    return (
      <Link to={routes.customer.channel.editHikvision(customerID, id, path)}>
        <Tooltip title={t('roles:edit_channel')}>
          <IconButton aria-label="edit" color="default" className={classes.iconEdit} size="medium">
            <Edit />
          </IconButton>
        </Tooltip>
      </Link>
    );
  }
  if (partner === PartnerType.INTELBRAS) {
    return (
      <Link to={routes.customer.channel.editIntelbras(customerID, id, path)}>
        <Tooltip title={t('roles:edit_channel')}>
          <IconButton aria-label="edit" color="default" className={classes.iconEdit} size="medium">
            <Edit />
          </IconButton>
        </Tooltip>
      </Link>
    );
  }

  if (protocol === 'RTMP') {
    return (
      <Link to={routes.customer.channel.editRTMP(customerID, id, path)}>
        <Tooltip title={t('roles:edit_channel')}>
          <IconButton aria-label="edit" color="default" className={classes.iconEdit} size="medium">
            <Edit />
          </IconButton>
        </Tooltip>
      </Link>
    );
  }

  return (
    <Link to={routes.customer.channel.edit(customerID, id, path)}>
      <Tooltip title={t('roles:edit_channel')}>
        <IconButton aria-label="edit" color="default" className={classes.iconEdit} size="medium">
          <Edit />
        </IconButton>
      </Tooltip>
    </Link>
  );
}

function ChannelDetails({ channel, customerId }: { channel: Channel; customerId: number }) {
  const { t } = useTranslation(['channels', '_common']);
  const theme = useTheme();
  const matchChannelView = useRouteMatch(routes.customer.channel.view(':customerId', ':channelId'));
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [mosaicsInChannelFlag] = useDecision(FEATURE_FLAGS_KEYS.MOSAICOS_VINCULADOS_AO_CANAL);

  return (
    <Container role="region" aria-label={channel.name}>
      <Header>
        <Permissioned role={PermissionRole.EDIT_CHANNEL}>
          <SendToEditForm
            customerID={customerId}
            id={channel.id}
            partner={channel.p2pPartner as PartnerType}
            protocol={channel.connectionTypeEnum}
            path={`${routes.customer.root()}?selectedCustomer=${customerId}`}
          />
        </Permissioned>
        <Permissioned role={PermissionRole.REMOVE_CHANNEL}>
          <Tooltip title={t('_common:delete_channel')}>
            <IconButton
              onClick={() => setOpenDeleteModal(true)}
              aria-label="delete"
              color="default"
              size="medium"
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Permissioned>
        {!matchChannelView?.isExact ? (
          <Link to={routes.customer.channel.view(customerId, channel.id)}>
            <Tooltip title={t('roles:view_channel')}>
              <IconButton aria-label="redirect" color="default" size="medium">
                <OpenInNew />
              </IconButton>
            </Tooltip>
          </Link>
        ) : (
          <Link to={routes.customer.channel.playback(customerId, channel.id)}>
            <Tooltip title={t('roles:view_channel_playback')}>
              <IconButton aria-label="video-cam-redirect" color="default" size="medium">
                <Videocam />
              </IconButton>
            </Tooltip>
          </Link>
        )}

        <Typography variant="h6">{channel.name}</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          ID {channel.idMask}
        </Typography>
      </Header>
      <InfoItem label={t('_common:status')}>
        {channel.status ? t('_common:actived') : t('_common:inactived')}
      </InfoItem>
      {channel.enablePreAlarm && (
        <InfoItem label={t('_common:pre_alarm')}>{t('_common:active')}</InfoItem>
      )}
      {channel.p2pPartner && (
        <InfoItem label={t('p2p_partner')}>
          {channel.p2pPartner === 'NO_P2P' ? t('channels:no_p2p') : channel.p2pPartner}
        </InfoItem>
      )}
      {channel.serialNumber && (
        <InfoItem label={t('channels:serial')}>{channel.serialNumber}</InfoItem>
      )}
      {channel.port && channel?.p2pPartner === PartnerType.INTELBRAS && (
        <InfoItem label={t('channels:port')}>{t(String(channel.port))}</InfoItem>
      )}
      <InfoItem
        label={
          channel.urlOnlyRecord
            ? t('rtsp_url_live')
            : t(checkProtocolByEnum(channel.connectionTypeEnum))
        }
      >
        {channel?.url && channel?.url.length > 50 ? (
          <Typography variant="body1">
            {channel.url.slice(0, 50)}
            <br />
            {channel.url.slice(50)}
          </Typography>
        ) : (
          channel.url
        )}
      </InfoItem>
      <InfoItem label={t('rtsp_recording_url')}>
        {channel.urlOnlyRecord && channel.urlOnlyRecord.length > 50 ? (
          <Typography variant="body1">
            {channel.urlOnlyRecord.slice(0, 50)}
            <br />
            {channel.urlOnlyRecord.slice(50)}
          </Typography>
        ) : (
          channel.urlOnlyRecord
        )}
      </InfoItem>
      {channel.channelGroup?.name && (
        <InfoItem label={t('channel_group')}>{channel.channelGroup.name}</InfoItem>
      )}
      <InfoItem label={t('recording_type')}>
        {t(`recording_type_${channel.recordingType}`)}
      </InfoItem>
      <InfoItem label={t('channel_status')}>
        <Box
          sx={{
            bgcolor: theme.palette.grey[300],
            borderRadius: '10px',
            width: '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '8px',
            paddingY: '2px',
          }}
        >
          <Box
            bgcolor={channel.recording ? theme.palette.success.main : theme.palette.error.main}
            height="8px"
            width="8px"
            borderRadius="4px"
            display="inline-block"
            marginRight="8px"
          />
          <Typography
            variant="body1"
            display="inline"
            color={channel.recording ? theme.palette.success.main : theme.palette.error.main}
          >
            {t(channel.recording ? 'channels:recording' : 'channels:not_recording')}
          </Typography>
        </Box>
      </InfoItem>

      {channel.simpleScheduledRecording?.name && (
        <InfoItem label={t('_common:recording_schedule')}>
          {channel.simpleScheduledRecording.name}
        </InfoItem>
      )}

      {channel.recordingType !== RecordingType.LIVE ? (
        <InfoItem label={t('total_hours_image_retention')}>
          <Typography>
            {channel.retentionTime} {t('_common:hours')}
          </Typography>
        </InfoItem>
      ) : null}

      <ApiBoundary>
        <InfoItem label={t('latest_events')}>
          <ApiBoundary fallbackLoading={<ChannelLatestEventsLoading />}>
            <ChannelLatestEvents channelId={channel.id} />
          </ApiBoundary>
        </InfoItem>
      </ApiBoundary>

      {mosaicsInChannelFlag.enabled && (
        <ApiBoundary fallbackLoading>
          <ChannelMosaicChips channelId={channel.id} />
        </ApiBoundary>
      )}
      <DeleteChannelDialog
        id={channel.id}
        channelName={channel.name}
        customerId={customerId}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
      />
    </Container>
  );
}

export default ChannelDetails;

interface DeleteChannelProp {
  id: number;
  channelName: string;
  openDeleteModal: boolean;
  setOpenDeleteModal: Dispatch<SetStateAction<boolean>>;
  customerId: number;
}

const DeleteChannelDialog = ({
  id,
  channelName,
  openDeleteModal,
  setOpenDeleteModal,
  customerId,
}: DeleteChannelProp) => {
  const [loading, setLoading] = useState(false);
  const { deleteChannel } = useChannelsApi(customerId);
  const { t } = useTranslation();

  const handleDeleteChannel = async () => {
    try {
      setLoading(true);
      await deleteChannel(id);
      setLoading(false);
      setOpenDeleteModal(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setOpenDeleteModal(false);
    }
  };

  return (
    <ConfirmModal
      open={openDeleteModal}
      setOpen={setOpenDeleteModal}
      doConfirm={handleDeleteChannel}
      disabled={loading}
      loading={loading}
      confirmActionColor="primary"
      variant="contained"
      confirmActionText={t('crud_actions:delete')}
      title={t('channels:want_delete_channel', {
        name: channelName,
      })}
      description={t('channels:not_possible_retrieve_information_and_view_image_camera')}
    />
  );
};
