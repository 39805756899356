import { atom, DefaultValue, selector } from 'recoil';
import { convertByte } from 'helpers/bytesOperations';
import { CalculatedItem, CalculatorEnteredData } from 'types/calculator.types';

const priceRecordingPerMinuteKey = 'priceRecordingPerMinute';
const priceLivePerMinuteKey = 'priceLivePerMinute';
const priceRecordingDisplayPerMinuteKey = 'priceRecordingDisplayPerMinute';
const priceUsedSpacePerGBKey = 'priceUsedSpacePerGB';
const priceRecordingTransferPerGBKey = 'priceRecordingTransferPerGB';
const priceliveStreamTransferPerGBKey = 'priceliveStreamTransferPerGB';
const priceTransferRecordedVideoPlaybackPerGBKey = 'priceTransferRecordedVideoPlaybackPerGB';
const marginMultiplierForUsedSpaceKey = 'marginMultiplierForUsedSpace';
const marginMultiplierForUseKey = 'marginMultiplierForUse';
const invoiceTaxesKey = 'invoiceTaxes';

export const priceRecordingPerMinuteAtom = atom<string>({
  key: priceRecordingPerMinuteKey,
  default: '0.0000638049493600966',
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      const value = localStorage.getItem(priceRecordingPerMinuteKey);
      if (value) {
        setSelf(JSON.parse(value));
      }
      onSet((newValue) => {
        if (newValue instanceof DefaultValue) {
          localStorage.removeItem(priceRecordingPerMinuteKey);
        } else {
          localStorage.setItem(priceRecordingPerMinuteKey, JSON.stringify(newValue));
        }
      });
    },
  ],
});

export const priceLivePerMinuteAtom = atom<string>({
  key: priceLivePerMinuteKey,
  default: '0.000024496875',
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      const value = localStorage.getItem(priceLivePerMinuteKey);
      if (value) {
        setSelf(JSON.parse(value));
      }
      onSet((newValue) => {
        if (newValue instanceof DefaultValue) {
          localStorage.removeItem(priceLivePerMinuteKey);
        } else {
          localStorage.setItem(priceLivePerMinuteKey, JSON.stringify(newValue));
        }
      });
    },
  ],
});

export const priceRecordingDisplayPerMinuteAtom = atom<string>({
  key: priceRecordingDisplayPerMinuteKey,
  default: '0.00000200575',
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      const value = localStorage.getItem(priceRecordingDisplayPerMinuteKey);
      if (value) {
        setSelf(JSON.parse(value));
      }
      onSet((newValue) => {
        if (newValue instanceof DefaultValue) {
          localStorage.removeItem(priceRecordingDisplayPerMinuteKey);
        } else {
          localStorage.setItem(priceRecordingDisplayPerMinuteKey, JSON.stringify(newValue));
        }
      });
    },
  ],
});

export const priceRecordingTransferPerGBAtom = atom<string>({
  key: priceRecordingTransferPerGBKey,
  default: '0.0042811392',
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      const value = localStorage.getItem(priceRecordingTransferPerGBKey);
      if (value) {
        setSelf(JSON.parse(value));
      }
      onSet((newValue) => {
        if (newValue instanceof DefaultValue) {
          localStorage.removeItem(priceRecordingTransferPerGBKey);
        } else {
          localStorage.setItem(priceRecordingTransferPerGBKey, JSON.stringify(newValue));
        }
      });
    },
  ],
});

export const priceliveStreamTransferPerGBAtom = atom<string>({
  key: priceliveStreamTransferPerGBKey,
  default: '0.0042811392',
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      const value = localStorage.getItem(priceliveStreamTransferPerGBKey);
      if (value) {
        setSelf(JSON.parse(value));
      }
      onSet((newValue) => {
        if (newValue instanceof DefaultValue) {
          localStorage.removeItem(priceliveStreamTransferPerGBKey);
        } else {
          localStorage.setItem(priceliveStreamTransferPerGBKey, JSON.stringify(newValue));
        }
      });
    },
  ],
});

export const priceTransferRecordedVideoPlaybackPerGBAtom = atom<string>({
  key: priceTransferRecordedVideoPlaybackPerGBKey,
  default: '0.04068',
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      const value = localStorage.getItem(priceTransferRecordedVideoPlaybackPerGBKey);
      if (value) {
        setSelf(JSON.parse(value));
      }
      onSet((newValue) => {
        if (newValue instanceof DefaultValue) {
          localStorage.removeItem(priceTransferRecordedVideoPlaybackPerGBKey);
        } else {
          localStorage.setItem(
            priceTransferRecordedVideoPlaybackPerGBKey,
            JSON.stringify(newValue)
          );
        }
      });
    },
  ],
});

export const priceUsedSpacePerGBAtom = atom<string>({
  key: priceUsedSpacePerGBKey,
  default: '0.15594',
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      const value = localStorage.getItem(priceUsedSpacePerGBKey);
      if (value) {
        setSelf(JSON.parse(value));
      }
      onSet((newValue) => {
        if (newValue instanceof DefaultValue) {
          localStorage.removeItem(priceUsedSpacePerGBKey);
        } else {
          localStorage.setItem(priceUsedSpacePerGBKey, JSON.stringify(newValue));
        }
      });
    },
  ],
});

export const marginMultiplierForUsedSpaceAtom = atom<string>({
  key: marginMultiplierForUsedSpaceKey,
  default: '1.4',
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      const value = localStorage.getItem(marginMultiplierForUsedSpaceKey);
      if (value) {
        setSelf(JSON.parse(value));
      }
      onSet((newValue) => {
        if (newValue instanceof DefaultValue) {
          localStorage.removeItem(marginMultiplierForUsedSpaceKey);
        } else {
          localStorage.setItem(marginMultiplierForUsedSpaceKey, JSON.stringify(newValue));
        }
      });
    },
  ],
});

export const marginMultiplierForUseAtom = atom<string>({
  key: marginMultiplierForUseKey,
  default: '3.5',
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      const value = localStorage.getItem(marginMultiplierForUseKey);
      if (value) {
        setSelf(JSON.parse(value));
      }
      onSet((newValue) => {
        if (newValue instanceof DefaultValue) {
          localStorage.removeItem(marginMultiplierForUseKey);
        } else {
          localStorage.setItem(marginMultiplierForUseKey, JSON.stringify(newValue));
        }
      });
    },
  ],
});

export const invoiceTaxesAtom = atom<string>({
  key: invoiceTaxesKey,
  default: '0.78',
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      const value = localStorage.getItem(invoiceTaxesKey);
      if (value) {
        setSelf(JSON.parse(value));
      }
      onSet((newValue) => {
        if (newValue instanceof DefaultValue) {
          localStorage.removeItem(invoiceTaxesKey);
        } else {
          localStorage.setItem(invoiceTaxesKey, JSON.stringify(newValue));
        }
      });
    },
  ],
});

export const addPricesModalOpenedAtom = atom<boolean>({
  key: 'addPricesModalOpened',
  default: false,
});

export const listChannelsAtom = atom<CalculatorEnteredData[]>({
  key: 'listChannelsAtom',
  default: [
    // {
    //   id: '',
    //   qtChannels: 1,
    //   resolution: '1280x720',
    //   fps: 30,
    //   bitrate: 1248,
    //   recordingType: RecordingType.EVENT,
    //   forecastTimeRecordedByMonth: 720,
    //   recordingRetentionTime: 24,
    //   timeForecastRecordingDisplay: 90,
    //   minutesExportedVideo: 0,
    //   timeForecastLiveDisplay: 90,
    // },
  ],
});

export type CheckedItemsIds = { [key: string]: boolean | undefined };
export const checkedItemsIdsAtom = atom<CheckedItemsIds>({
  key: 'checkedItemsIdsAtom',
  default: {},
});

export const editingItemAtom = atom<CalculatorEnteredData | undefined>({
  key: 'editingItemAtom',
  default: undefined,
});

export const calculatedItemsSelector = selector<CalculatedItem[]>({
  key: 'calculatedItemsSelector',
  get: ({ get }) =>
    get(listChannelsAtom).map(
      ({
        id,
        bitrate,
        forecastTimeRecordedByMonth,
        qtChannels,
        timeForecastLiveDisplay,
        timeForecastRecordingDisplay,
        recordingRetentionTime,
        quantityOfRecordingsByMonth,
      }) => {
        const bytesPerSecond = bitrate / 8;

        const totalRecordingTransferGB =
          ((bytesPerSecond * forecastTimeRecordedByMonth * 60 * 60) / 1024 / 1024) * qtChannels; // total GBbytes por segundo para todos os canais
        const liveStreamTransferGB =
          ((bytesPerSecond * timeForecastLiveDisplay * 60 * 60) / 1024 / 1024) * qtChannels;
        const transferRecordedVideoPlaybackGB =
          ((bytesPerSecond * timeForecastRecordingDisplay * 60 * 60) / 1024 / 1024) * qtChannels;
        const totalSpaceUsed =
          recordingRetentionTime > 720
            ? totalRecordingTransferGB +
              ((bytesPerSecond * (recordingRetentionTime - 720) * 60 * 60) / 1024 / 1024) *
                qtChannels
            : Math.min(
                ((bytesPerSecond * recordingRetentionTime * 60 * 60) / 1024 / 1024) * qtChannels,
                totalRecordingTransferGB
              );

        // Para cada gravação, temos 90 segundos por conta do pre alarme.
        const totalRecordingSecondsByMonth = quantityOfRecordingsByMonth * 90;

        const totalRecordingSecondsByMonthByChannel = totalRecordingSecondsByMonth * qtChannels;

        // Converte os segundos para minutos
        const totalMinutesOfRecordingsByMonth = totalRecordingSecondsByMonthByChannel / 60;

        // Preço por minuto gravado
        const pricePerRecordedMinute = Number(get(priceRecordingPerMinuteAtom));

        // Preço total por minuto gravado
        const totalMinutesPrice = pricePerRecordedMinute * totalMinutesOfRecordingsByMonth;

        // Preço total por minuto gravado sem margem
        const totalRecordedMinutesPriceWithoutMargin =
          totalMinutesPrice * forecastTimeRecordedByMonth * 60;

        // Preço total por minuto gravado com margem (reflete na tela)
        const totalRecordedMinutesPrice =
          (totalRecordedMinutesPriceWithoutMargin * Number(get(marginMultiplierForUseAtom)) +
            totalRecordedMinutesPriceWithoutMargin) /
          Number(get(invoiceTaxesAtom));

        const totalRecordingTransferWithoutMargin =
          totalRecordingTransferGB * Number(get(priceRecordingTransferPerGBAtom));
        const totalRecordingTransferPrice =
          (totalRecordingTransferWithoutMargin * Number(get(marginMultiplierForUseAtom)) +
            totalRecordingTransferWithoutMargin) /
          Number(get(invoiceTaxesAtom));

        const totalRecordingPerMinutePriceWithoutMargin =
          Number(get(priceRecordingPerMinuteAtom)) * qtChannels * forecastTimeRecordedByMonth * 60;
        const totalRecordingPerMinutePrice =
          (totalRecordingPerMinutePriceWithoutMargin * Number(get(marginMultiplierForUseAtom)) +
            totalRecordingPerMinutePriceWithoutMargin) /
          Number(get(invoiceTaxesAtom));

        const totalLiveStreamTransferPriceWithoutMargin =
          liveStreamTransferGB * Number(get(priceliveStreamTransferPerGBAtom));
        const totalLiveStreamTransferPrice =
          (totalLiveStreamTransferPriceWithoutMargin * Number(get(marginMultiplierForUseAtom)) +
            totalLiveStreamTransferPriceWithoutMargin) /
          Number(get(invoiceTaxesAtom));

        const totalLivePerMinutePriceWithoutMargin =
          Number(get(priceLivePerMinuteAtom)) * qtChannels * timeForecastLiveDisplay * 60;
        const totalLivePerMinutePrice =
          (totalLivePerMinutePriceWithoutMargin * Number(get(marginMultiplierForUseAtom)) +
            totalLivePerMinutePriceWithoutMargin) /
          Number(get(invoiceTaxesAtom));

        const totalRecordingDisplayPerMinutePriceWithoutMargin =
          Number(get(priceRecordingDisplayPerMinuteAtom)) *
          qtChannels *
          timeForecastRecordingDisplay *
          60;
        const totalRecordingDisplayPerMinutePrice =
          (totalRecordingDisplayPerMinutePriceWithoutMargin *
            Number(get(marginMultiplierForUseAtom)) +
            totalRecordingDisplayPerMinutePriceWithoutMargin) /
          Number(get(invoiceTaxesAtom));

        const totalTransferRecordedVideoPlaybackPriceWithoutMargin =
          Number(get(priceTransferRecordedVideoPlaybackPerGBAtom)) *
          transferRecordedVideoPlaybackGB;
        const totalTransferRecordedVideoPlaybackPrice =
          (totalTransferRecordedVideoPlaybackPriceWithoutMargin *
            Number(get(marginMultiplierForUseAtom)) +
            totalTransferRecordedVideoPlaybackPriceWithoutMargin) /
          Number(get(invoiceTaxesAtom));

        const totalSpaceUsedPriceWithoutMargin =
          Number(get(priceUsedSpacePerGBAtom)) * totalSpaceUsed;
        const totalSpaceUsedPrice =
          (totalSpaceUsedPriceWithoutMargin * Number(get(marginMultiplierForUsedSpaceAtom)) +
            totalSpaceUsedPriceWithoutMargin) /
          Number(get(invoiceTaxesAtom));

        const totalPrice =
          totalRecordingTransferPrice +
          totalRecordingPerMinutePrice +
          totalLiveStreamTransferPrice +
          totalLivePerMinutePrice +
          totalRecordedMinutesPrice +
          totalRecordingDisplayPerMinutePrice +
          totalTransferRecordedVideoPlaybackPrice;

        const totalPricePerChannel = totalPrice / qtChannels;

        return {
          id,
          totalRecordingTransferGB,
          liveStreamTransferGB,
          transferRecordedVideoPlaybackGB,
          totalSpaceUsed,
          totalRecordingTransferPrice,
          totalRecordingPerMinutePrice,
          totalLiveStreamTransferPrice,
          totalLivePerMinutePrice,
          totalRecordingDisplayPerMinutePrice,
          totalTransferRecordedVideoPlaybackPrice,
          totalSpaceUsedPrice,
          totalPrice,
          totalPricePerChannel,
          totalRecordedMinutesPrice,
        };
      }
    ),
});

export const selectedPlanAtom = atom<{
  storageSizeBytes: number;
  value: number;
}>({
  key: 'selectedPlanAtom',
  default: {
    storageSizeBytes: convertByte(25, 'GB', 'Bytes'),
    value: 0,
  },
});

export const expandAllCalculatorTable = atom<boolean>({
  key: 'expandAllCalculatorTable',
  default: false,
});

export const checkedAllCalculatorTable = atom<boolean>({
  key: 'checkedAllCalculatorTable',
  default: true,
});

export const calculatorRowIsChecked = atom<boolean>({
  key: 'calculatorRowIsChecked',
  default: true,
});

export const totalChannelsCalculatedSelector = selector<number>({
  key: 'totalChannelsCalculatedSelector',
  get: ({ get }) =>
    get(listChannelsAtom)
      .filter((item) => get(selectedRows).includes(item.id))
      .reduce((sum, item) => item.qtChannels + sum, 0),
});

export const totalStorageCalculatedSelector = selector<number>({
  key: 'totalStorageCalculatedSelector',
  get: ({ get }) =>
    get(calculatedItemsSelector)
      .filter((item) => get(selectedRows).includes(item.id))
      .reduce((sum, item) => item.totalSpaceUsed + sum, 0),
});

export const totalValueCalculatedSelector = selector<number>({
  key: 'totalValueCalculatedSelector',
  get: ({ get }) =>
    get(calculatedItemsSelector)
      .filter((item) => get(selectedRows).includes(item.id))
      .reduce((sum, item) => item.totalPrice + sum, 0),
});

export const selectedRows = atom<string[]>({
  key: 'selectedRows',
  default: [],
});
