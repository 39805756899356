import React, { Suspense } from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { useFetch } from 'hooks';
import { useDecision } from '@optimizely/react-sdk';
import FEATURE_FLAGS_KEYS from 'constants/featureFlagsKeys';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '1.375rem',
    marginRight: theme.spacing(1),
  },
}));

interface ChannelsRecordingTotal {
  totalChannel: number;
  recordingChannel: number;
  notRecordingChannel: number;
  online: number;
  offline: number;
}

interface ChannelsRecordingTotalNormalized {
  name: string;
  total: number;
  color: string;
}

function ChannelsMonitoringTopBar() {
  const { t } = useTranslation('channels_monitoring');
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title} variant="h4">
        {t('channels_monitoring')?.toUpperCase()}
      </Typography>
      <Suspense fallback={null}>
        <ChannelsMonitoringCharts />
      </Suspense>
    </Box>
  );
}

function ChannelsMonitoringCharts() {
  const { t } = useTranslation('channels_monitoring');
  const theme = useTheme();
  const [monitoringPage] = useDecision(FEATURE_FLAGS_KEYS.MONITORING_PAGE);
  const { data: channelsTotal } = useFetch<
    ChannelsRecordingTotal,
    ChannelsRecordingTotalNormalized[]
  >('/v1/channels/recording/total', {
    refreshInterval: 1500,
    normalizeData: (data) => [
      {
        name: t('recording'),
        // @ts-ignore
        total: monitoringPage.enabled ? data.recordingChannel : data.onlineChannel,
        color: theme.palette.success.main,
      },
      {
        name: t('not_recording'),
        // @ts-ignore
        total: monitoringPage.enabled ? data.notRecordingChannel : data.offlineChannel,
        color: theme.palette.error.main,
      },
      { name: t('_common:online'), total: data.online, color: theme.palette.success.main },
      {
        name: t('channels_monitoring:offline'),
        total: data.offline,
        color: theme.palette.error.main,
      },
    ],
  });

  if (!channelsTotal?.length) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 2,
      }}
    >
      <ChannelsMonitoringPie data={[channelsTotal[0], channelsTotal[1]]} width={240} />
      {monitoringPage.enabled && (
        <ChannelsMonitoringPie data={[channelsTotal[2], channelsTotal[3]]} width={210} />
      )}
    </Box>
  );
}

function ChannelsMonitoringPie({
  data,
  width,
}: {
  data?: ChannelsRecordingTotalNormalized[];
  width: number;
}) {
  return (
    <ResponsiveContainer width={width} height={50}>
      <PieChart height={50} width={50}>
        <Pie
          dataKey="total"
          data={data}
          cx={25}
          innerRadius="50%"
          outerRadius="100%"
          paddingAngle={5}
        >
          {data?.map((entry, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Cell key={`cell-${index}`} fill={entry.color} stroke="0" />
          ))}
        </Pie>
        <Legend
          layout="vertical"
          align="right"
          iconType="circle"
          iconSize={8}
          formatter={(value, entry) => (
            <Typography variant="body2" display="inline">{`${value} (${
              data?.find((ct) => ct.name === value)?.total || 0
            })`}</Typography>
          )}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default ChannelsMonitoringTopBar;
